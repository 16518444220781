import "firebase/firestore"

import firebase from "firebase/app"

var firebaseConfig = {
  apiKey: "AIzaSyDv-tabcoouwQwZ1xZQ6rczEJtxOscktew",
  authDomain: "gatsby-reviews.firebaseapp.com",
  projectId: "gatsby-reviews",
  storageBucket: "gatsby-reviews.appspot.com",
  messagingSenderId: "376327175761",
  appId: "1:376327175761:web:f9e3e5eb41cccedf090270",
  measurementId: "G-S9FCMV410E",
}

firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()

export default firebase
