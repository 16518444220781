import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BlogDetailsWrapper = styled.div`
  position: relative;
  background: linear-gradient(180deg, #e5f6fb 50%, #fff 50%);
  padding: 60px 0 60px;
  @media only screen and (max-width: 1220px) {
    padding: 50px 0 50px;
  }
  @media only screen and (max-width: 990px) {
    padding: 40px 0 40px;
  }
  @media only screen and (max-width: 480px) {
    padding: 25px 0 25px;
    margin-top: 0px;
  }

  .container {
    background-color: ${themeGet("colors.lightGrey")};
    padding: 0px 80px;

    @media (max-width: 990px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media only screen and (max-width: 480px) {
      padding: 0px 30px 0px;
    }
  }
`

export const Social = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 60px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  @media only screen and (max-width: 990px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    padding: 30px 0px;
  }

  .stars {
    margin-top: -4px;
  }

  .social-wrapper {
    @media only screen and (max-width: 990px) {
      padding: 10px;
      margin: 0px;
    }

    .icon-container {
      display: flex;
      flex-wrap: wrap;
      @media only screen and (max-width: 700px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .icon-container .icon-wrapper:last-child {
    margin-right: 0px !important;
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: 40px;
    @media only screen and (max-width: 700px) {
      margin-right: 0px;
      margin-bottom: 8px;
    }

    span {
      color: ${themeGet("colors.mainBlue")};
      font-size: 18px;
      margin-top: -4px;
      margin-left: 16px;
    }
  }

  .social-header {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 44px;
    @media only screen and (max-width: 990px) {
      margin-bottom: 30px;
      text-align: center;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 10px;
    }
  }
  .MuiRating-label {
    color: ${themeGet("colors.mainBlue")};
    margin-right: 4px;
  }
`

export const AuthorDetails = styled.div`
  padding: 60px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
    padding: 50px;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    padding: 30px 0px;
  }

  .author {
    display: flex;
    align-items: center;
    .author-avatar {
      width: 88px;
      border-radius: 50%;
      margin-right: 42px;
    }
    p {
      color: ${themeGet("colors.black")};
      margin: 0;
    }
    .author-name {
      font-weight: 700;
      font-size: 24px;
      text-transform: capitalize;
      line-height: 30px;
      margin-bottom: 4px;
      @media only screen and (max-width: 990px) {
        font-size: 22px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .author-occupation {
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
      @media only screen and (max-width: 990px) {
        font-size: 16px;
      }
    }
  }

  .more {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 480px) {
      margin-top: 20px;
    }
  }

  .articles {
    color: ${themeGet("colors.mainBlue")};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.4px;
    margin-bottom: 0px;
    margin-right: 56px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
      margin-top: 20px;
      text-align: center;
      line-height: 28px;
      letter-spacing: 0px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 12px;
      line-height: 20px;
      margin-top: 0px;
    }
  }
`

export default BlogDetailsWrapper
