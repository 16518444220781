import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import BlogDetails from "../containers/BlogDetails"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import SlicesZone from "../containers/Sliceszone"
import { ContentWrapper } from "../containers/alpacked.style"
import { firestore } from "../../firebase"

const BlogPostTemplate = ({ data, location }) => {
  const [getReview, setGetReview] = useState([])

  useEffect(() => {
    const cleanUp = firestore.collection(`reviews`).onSnapshot(snapshot => {
      const reviews = snapshot.docs
        .filter(doc => doc.data().slug === data.prismicBlogPost.uid)
        .map(doc => {
          return { id: doc.id, ...doc.data() }
        })
      setGetReview(reviews)
    })
    return () => cleanUp()
  }, [data.prismicBlogPost.uid])

  const {
    seo_title,
    seo_short_description,
    seo_description,
    image_link_preview,
    body,
    title,
  } = data.prismicBlogPost.data

  const {
    first_publication_date,
    data: { author },
  } = data.prismicBlogPost

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
        blogPostData={data.prismicBlogPost}
      />
      <Layout pathname={location.pathname}>
        <ContentWrapper>
          <SlicesZone
            content={body}
            date={first_publication_date}
            breadCrumbsContent={{
              category: { title: "Blog", url: "blog" },
              page: {
                title: title.text,
              },
            }}
          />
          <BlogDetails
            getReview={getReview}
            author={author}
            url={data.prismicBlogPost.uid}
            title={title.text}
          />
          {/* {filteredRelatedBlogPost.length > 0 && (
            <RelatedArticles content={filteredRelatedBlogPost} />
          )} */}

          <Consultation extraPadding pathname={location.pathname} />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withPreview(BlogPostTemplate)

export const BlogPostPage = graphql`
  query blogPostContent($slug: String!, $category: String!) {
    allPrismicBlogPost(
      filter: { uid: { ne: $slug }, data: { category: { eq: $category } } }
      limit: 3
      sort: { order: DESC, fields: last_publication_date }
    ) {
      edges {
        node {
          uid
          id
          tags
          first_publication_date(formatString: "MMM DD, YYYY")
          data {
            category
            featured_article
            intro_text
            title {
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            author {
              slug
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                    occupation {
                      text
                    }
                    image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 60, quality: 72) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicBlogPost(uid: { eq: $slug }) {
      first_publication_date(formatString: "MMM DD, YYYY")
      uid
      data {
        seo_title {
          text
        }
        seo_short_description {
          text
        }
        seo_description {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        title {
          text
        }

        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                occupation {
                  text
                }
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 72) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              uid
            }
          }
        }
        body {
          ... on PrismicBlogPostBodyBreadcrumbs {
            id
            slice_type
            primary {
              enabled
            }
          }
          ... on PrismicBlogPostBodyCases {
            id
            slice_type
            items {
              image1 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            primary {
              title1 {
                html
              }
              title_colored {
                html
              }
              description
            }
          }
          ... on PrismicBlogPostBodyQuote {
            id
            slice_type
            primary {
              quote_text
              author_occupation
              author_name
              author_image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBlogPostBodyStatistics {
            id
            slice_type
            items {
              subtitle
              title {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyServicesAlt {
            id
            slice_type
            primary {
              description
              title1 {
                html
              }
              title_colored {
                html
              }
            }
            items {
              image1 {
                url
              }
              desServices: description {
                text
              }
              service_name {
                text
              }
            }
          }
          ... on PrismicBlogPostBodyRelatedServices {
            id
            primary {
              title1 {
                html
              }
            }
            items {
              icon {
                url
              }
              service_title {
                uid
                document {
                  ... on PrismicServicesPage {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicConsulting {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicSingleTechnologiesPage {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicBlogPostBodyTable {
            id
            slice_type
            primary {
              desTable: description {
                html
              }
              table_head_column_a
              table_head_column_b
              table_head_column_c
              table_head_column_d
              title1 {
                html
              }
              title_colored {
                html
              }
            }
            items {
              raw_column_b {
                html
              }
              raw_column_a {
                html
              }
              raw_column_c {
                html
              }
              raw_column_d {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyContent {
            id
            slice_type
            items {
              image_position
              title_width
              title1 {
                html
              }
              title_colored {
                html
              }
              free_content {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyTableOfContents {
            id
            primary {
              enable_content_table
              intro_text1 {
                html
              }
            }
            slice_type
          }
          ... on PrismicBlogPostBodyBenefits1 {
            id
            slice_type
            primary {
              number_of_columns
              title1 {
                html
              }
              title_colored {
                html
              }
              desBenefits: description {
                html
              }
            }
            items {
              benefit_description {
                html
              }
              benefit_title {
                text
              }
              icon {
                url
              }
            }
          }
          ... on PrismicBlogPostBodyBannerBlog {
            id
            slice_type
            primary {
              subtitle
              title1 {
                html
              }
              image1 {
                url
              }
            }
          }
          ... on PrismicBlogPostBodyRelatedBlogPosts {
            id
            slice_type
            items {
              related_blog_posts {
                document {
                  ... on PrismicBlogPost {
                    id
                    data {
                      intro_text
                      title {
                        text
                      }
                      category
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 800, quality: 72) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                          }
                        }
                      }
                      author {
                        slug
                        document {
                          ... on PrismicAuthor {
                            id
                            data {
                              name {
                                text
                              }
                              occupation {
                                text
                              }
                              image {
                                localFile {
                                  childImageSharp {
                                    fluid(maxWidth: 60, quality: 72) {
                                      ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    uid
                    tags
                    first_publication_date(formatString: "MMM DD, YYYY")
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
