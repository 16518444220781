import BlogDetailsWrapper, { AuthorDetails, Social } from "./blogDetails.style"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share"
import React, { useEffect, useState } from "react"

import ArrowRight from "../../common/assets/image/alpacked/blog/arrow-right.svg"
/* eslint-disable */
import Box from "@material-ui/core/Box"
import Container from "../../components/UI/Container"
import Cookies from "js-cookie"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Rating from "react-rating"
import Star from "../../common/assets/image/alpacked/blog/star.svg"
import StarEmpty from "../../common/assets/image/alpacked/blog/star-empty.svg"
import Text from "../../components/Text"
import { firestore } from "../../../firebase"

const BlogDetails = ({ author, url, title, getReview }) => {
  let score = 0
  const AverageRating = getReview.map(item => {
    score = score + item.stars
  })

  const [myRating, setMyRating] = useState(undefined)

  const [publicRating, setPublicRating] = useState(0)

  useEffect(() => {
    setPublicRating(getReview[0]?.stars)
  }, [getReview[0]])

  const handleCommentSubmission = async num => {
    if (Cookies.get(`slug${url}`) === url) {
      Cookies.remove(`slug${url}`)
      Cookies.remove(`stars${url}`)
    }
    Cookies.set(`stars${url}`, num, { expires: 90 })
    Cookies.set(`slug${url}`, url, { expires: 90 })
    setMyRating(num)
    let review = {
      stars: num,
      slug: url,
    }
    firestore
      .collection(`reviews`)
      .add(review)
      .catch(err => {
        console.error("error adding review: ", err)
      })
  }

  return (
    <BlogDetailsWrapper>
      <Container width="1160px">
        <Social>
          <Box className="social-wrapper">
            <Text content="RATE THIS ARTICLE:" className="social-header" />
            {Cookies.get(`slug${url}`) !== url ? (
              <Rating
                className="stars"
                placeholderRating={
                  Cookies.get(`stars${url}`) &&
                  Cookies.get(`slug${url}`) === url
                    ? Cookies.get(`stars${url}`)
                    : 0
                }
                onChange={num => handleCommentSubmission(num)}
                emptySymbol={
                  <img
                    loading="lazy"
                    src={StarEmpty}
                    className="icon"
                    alt="empty-star"
                  />
                }
                fullSymbol={
                  <img
                    loading="lazy"
                    src={Star}
                    className="icon"
                    alt="full-star"
                  />
                }
                placeholderSymbol={
                  <img
                    loading="lazy"
                    src={Star}
                    className="icon"
                    alt="placeholder-star"
                  />
                }
              />
            ) : (
              <>
                <div>Thanks for your voting!</div>
                <div style={{ fontSize: "14px" }}>
                  Average rating{" "}
                  <span
                    style={{
                      color: "#21AAD4",
                      fontWeight: "700",
                      fontSize: "16px",
                      marginLeft: "4px",
                    }}
                  >
                    "{(score / getReview.length).toFixed(0)}"
                  </span>
                </div>
              </>
            )}
          </Box>
          <Box className="social-wrapper">
            <Text content="SHARE THIS ARTICLE:" className="social-header" />
            <div className="icon-container">
              <FacebookShareButton
                className="icon-wrapper"
                url={`https://dev.alpacked.io/blog/${url}`}
                quote={title}
                hashtag=""
              >
                <FacebookIcon
                  round="true"
                  bgStyle={{ fill: "21AAD4" }}
                  size="40px"
                />
                <span>Facebook</span>
              </FacebookShareButton>

              <LinkedinShareButton
                className="icon-wrapper"
                url={`https://dev.alpacked.io/blog/${url}`}
                quote={title}
                summary=""
              >
                <LinkedinIcon
                  round="true"
                  bgStyle={{ fill: "21AAD4" }}
                  size="40px"
                />
                <span>Linkedin</span>
              </LinkedinShareButton>

              <EmailShareButton
                className="icon-wrapper"
                subject={title}
                body={`https://dev.alpacked.io/blog/${url}`}
                title=""
                quote=""
                hashtags={[""]}
              >
                <EmailIcon
                  round="true"
                  bgStyle={{ fill: "21AAD4" }}
                  size="40px"
                />
                <span>Email</span>
              </EmailShareButton>
            </div>
          </Box>
        </Social>
        <AuthorDetails>
          <div className="author">
            <Img
              fadeIn={false}
              loading="lazy"
              fluid={
                author.document.data.image.localFile?.childImageSharp.fluid
              }
              alt={author.document.data.name.text}
              className="author-avatar"
            />
            <div>
              <Link to={`/blog/authors/${author.document.uid}/`}>
                <p className="author-name">{author.document.data.name.text}</p>
                <p className="author-occupation">
                  {author.document.data.occupation.text}
                </p>
              </Link>
            </div>
          </div>
          <div>
            <Link to={`/blog/authors/${author.document.uid}/`} className="more">
              <Text
                className="articles"
                content={`See all articles by ${author.document.data.name.text.replace(
                  / .*/,
                  ""
                )}`}
              />
              <img loading="lazy" src={ArrowRight} alt="arrow-right" />
            </Link>
          </div>
        </AuthorDetails>
      </Container>
    </BlogDetailsWrapper>
  )
}

export default BlogDetails
